import React, { useEffect, useState } from 'react';
import {
	Box,
	Grid,
	makeStyles,
	withStyles,
	FormControlLabel,
	Checkbox,
	useMediaQuery,
	useTheme,
	Divider,
} from '@material-ui/core';
import Loader from '../../assets/images/Loader.svg';
import 'react-loading-skeleton/dist/skeleton.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import FlightDetailModal from '../../components/reusableComponents/FlightDetailModal';
import flightrun from '../../assets/images/flightrun.png';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AddSearchFlightFDDispatch } from '../../reducers/HomeReducer';
import Drawers from '../../components/Drawers';
import Login from '../../components/reusableComponents/Login';
import flightnotfound from '../../assets/images/flightnotfound.png';
import HeaderSection from '../../components/reusableComponents/HeaderSection';
import SkeletonOneway from '../../components/reusableComponents/SkeletonOneway';
import OneWayMobileRow from '../Mobile/OneWayMobileRow';
import Linear from '../../components/reusableComponents/Linear';
import OneWayDesktopRow from '../../components/reusableComponents/OneWayDesktopRow';
import { fdSearchDispatch } from '../../reducers/FDReducer';
import FDOnewayrow from '../../components/reusableComponents/FDOnewayrow';
import Footer from './Footer';
import Modifyfdform from '../../form/Modifyfdform';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiChip-sizeSmall': {
			height: 36,
		},
	},
	accodin: {
		'& .MuiAccordionSummary-content': {
			justifyContent: 'space-between',
		},
	},
	searhform: {
		marginTop: 60,
		paddingBottom: 10,
		paddingLeft: 20,
		paddingRight: 20,
		backgroundColor: 'midnightblue',
		[theme.breakpoints.down('xs')]: {
			marginTop: 85,
		},
	},
	radio: {
		'& .MuiTypography-body1': {
			fontSize: 13,
		},
	},
}));

const GreenCheckbox = withStyles({
	root: {
		color: 'rgb(239, 51, 57)',
		'&$checked': {
			color: 'rgb(239, 51, 57)',
		},
	},
	checked: {},
})((props) => <Checkbox color='default' {...props} />);
const FDOneway = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [expanded, setExpanded] = React.useState('');
	const [stopcheck1, setStopCheck1] = useState(false);
	const [stopcheck2, setStopCheck2] = useState(false);
	const [stopcheck3, setStopCheck3] = useState(false);
	const [airlineS, setairlineS] = useState(false);
	const [airlineG, setairlineG] = useState(false);
	const [airlineI, setairlineI] = useState(false);
	const [airlineA, setairlineA] = useState(false);
	const [airlineV, setairlineV] = useState(false);
	const [morning, setMorning] = useState(false);
	const [refund, setRefund] = useState(false);
	const [nrefund, setNRefund] = useState(false);
	const [seg, setSeg] = useState(null);
	const [net, setNet] = useState(false);
	const [check2, setCheck2] = useState(false);
	const [check3, setCheck3] = useState(false);
	const [check4, setCheck4] = useState(false);
	const [check5, setCheck5] = useState(false);
	const [rule, SetRule] = useState(false);
	const [scrollVal, SetScrollVal] = useState(10);
	const [scrollValResult, SetScrollValResult] = useState(10);
	const [hasMoreresult, SethasMoreResult] = useState(true);
	const [hasMorefilterData, SethasMoreFilterData] = useState(true);
	const [result, setResult] = useState([]);
	const history = useHistory();
	const theme = useTheme();
	const location = useLocation();
	const [air, setAir] = useState(true);
	const [st, setSt] = useState(true);
	const [stp, setStp] = useState(true);
	const [out, setOut] = useState(true);
	const [rf, setRf] = useState(true);
	const [toggle, setToggle] = useState(false);
	const {
		search,
		loading,
		onewayflightdata,
		onewayflightdataEtrav,
		tripjack,
		holdflt,
		currency,
		ltbo,
		letrav,
		loading2,
	} = useSelector((state) => state.home);
	const { selectedOrigin, selectedDepart, selectedFDSector } = useSelector(
		(state) => state.ui
	);
	const { agentlogin } = useSelector((state) => state.user);
	const { loadingfd, fdresult, holdresult } = useSelector(
		(state) => state.promo
	);
	const [stop1, setStop1] = useState(false);
	const [value, setValue] = React.useState([1000, 100000]);

	const [stop2, setStop2] = useState(false);
	const [stop3, setStop3] = useState(false);
	const { AdultCount, ChildCount, InfantCount } = location.state.body;
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const matches2 = useMediaQuery(theme.breakpoints.down('xs'));
	function convertH2M(timeInHour) {
		// console.log(timeInHour);
		var timeParts = timeInHour.split(':');
		return Number(timeParts[0]) * 60 + Number(timeParts[1]);
	}
	function timeConvert(n) {
		var num = n;
		var hours = num / 60;
		var rhours = Math.floor(hours);
		var minutes = (hours - rhours) * 60;
		var rminutes = Math.round(minutes);
		return rhours + ' hr and ' + rminutes + ' min.';
	}
	console.log('convertH2M', convertH2M('07:20'));

	const fetchMoreData = () => {
		if (ltbo === false && letrav === false) {
			if (scrollVal >= fdresult.result.length) {
				SethasMoreFilterData(false);
			}
		}

		setTimeout(() => {
			SetScrollVal(scrollVal + 5);
		}, 500);
	};
	const fetchMoreDataResult = () => {
		if (scrollValResult >= fdresult.result.length) {
			SethasMoreResult(false);
		}
		setTimeout(() => {
			SetScrollValResult(scrollValResult + 5);
		}, 500);
	};

	useEffect(() => {
		if (location.state) {
			dispatch(fdSearchDispatch(location.state.body));
			dispatch(AddSearchFlightFDDispatch(location.state.body2));
			window.scrollTo(0, 0);
		}
	}, []);
	console.log('onewayflightdata', onewayflightdata);
	let filterData = [];
	let combineflightData = [];
	let filterAirlineTBO = [];
	let filterAirlineETR = [];
	let filterAirlineAIQ = [];
	let combineAirlineCode = [];
	if (onewayflightdata) {
		onewayflightdata &&
			onewayflightdata?.result &&
			onewayflightdata?.result?.Response &&
			onewayflightdata?.result?.Response?.Results[0].length > 0 &&
			onewayflightdata?.result?.Response?.Results[0].map((item) => {
				if (item?.FareClassification?.Type === 'Inst_SeriesPur') {
					combineflightData.push({
						...item,
					});
				}
			});
		if (onewayflightdata?.result?.Response?.Results[0].length > 0) {
			filterAirlineTBO = [
				...new Set(
					onewayflightdata &&
						onewayflightdata?.result &&
						onewayflightdata?.result?.Response &&
						onewayflightdata?.result?.Response?.Results[0].length > 0 &&
						onewayflightdata?.result?.Response?.Results[0].map(
							(item) => item.AirlineCode
						)
				),
			];
		}
	}
	if (onewayflightdataEtrav) {
		onewayflightdataEtrav &&
			onewayflightdataEtrav?.result &&
			onewayflightdataEtrav?.result?.TripDetails &&
			onewayflightdataEtrav?.result?.TripDetails[0]?.Flights.length > 0 &&
			onewayflightdataEtrav?.result?.TripDetails[0]?.Flights.map((item) => {
				if (item?.Fares?.Warning === null || item?.Fares?.Warning === '') {
				} else {
					combineflightData.push({
						...item,
					});
				}
			});
		if (onewayflightdataEtrav?.result?.TripDetails[0]?.Flights.length > 0) {
			filterAirlineETR = [
				...new Set(
					onewayflightdataEtrav &&
						onewayflightdataEtrav?.result &&
						onewayflightdataEtrav?.result?.TripDetails &&
						onewayflightdataEtrav?.result?.TripDetails[0]?.Flights.length > 0 &&
						onewayflightdataEtrav?.result?.TripDetails[0]?.Flights?.map(
							(item) => item.Airline_Code
						)
				),
			];
		}
	}
	if (holdresult) {
		holdresult &&
			holdresult?.searchResult &&
			holdresult?.searchResult.length > 0 &&
			holdresult?.searchResult.map((item) => {
				combineflightData.push({
					...item,
				});
			});
	}
	if (fdresult?.result === 'Data not found') {
	} else {
		fdresult &&
			fdresult?.result &&
			fdresult?.result.length > 0 &&
			fdresult?.result?.map((item) => {
				combineflightData.push({
					...item,
				});
			});
	}
	if (combineflightData && combineflightData.length > 0) {
		filterData =
			combineflightData &&
			combineflightData.length > 0 &&
			combineflightData.slice().sort((a, b) => {
				let first = a.Fare
					? Number(
							Number(Math.round(a.Fare.Total_Amount)) -
								Number(Math.round(a.Fare.MFB_Discount))
					  )
					: a.Fares
					? Number(Math.round(a.Fares.Total_Amount))
					: a.totalPriceList
					? Number(Math.round(a.totalPriceList.fd.Total_Amount))
					: a.Supplier === 'MFB'
					? Number(Number(Math.round(a.Total_Amount)))
					: Number(Number(Math.round(a.totalFare)));
				let second = b.Fare
					? Number(
							Number(Math.round(b.Fare.Total_Amount)) -
								Number(Math.round(b.Fare.MFB_Discount))
					  )
					: b.Fares
					? Number(Math.round(b.Fares.Total_Amount))
					: b.totalPriceList
					? Number(Math.round(b.totalPriceList.fd.Total_Amount))
					: b.Supplier === 'MFB'
					? Number(Number(Math.round(b.Total_Amount)))
					: Number(Number(Math.round(b.totalFare)));
				return first - second;
			});
	}
	const handleAirline = (event) => {
		SethasMoreResult(true);
		SetScrollValResult(10);
		if (event.target.name === 'SG') {
			setairlineS(event.target.checked);
		} else if (event.target.name === 'G8') {
			setairlineG(event.target.checked);
		} else if (event.target.name === '6E') {
			setairlineI(event.target.checked);
		} else if (event.target.name === 'I5') {
			setairlineA(event.target.checked);
		} else if (event.target.name === 'UK') {
			setairlineV(event.target.checked);
		}

		if (airlineA || airlineG || airlineI || airlineS || airlineV) {
			setResult([]);
			SetRule(event.target.checked);
		} else {
			SetRule(event.target.checked);
			console.log(event.target.name);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];
			let etrav = [];
			let tjk = [];
			if (onewayflightdata) {
				onewayflightdata &&
					onewayflightdata &&
					onewayflightdata.result &&
					onewayflightdata.result.Response &&
					onewayflightdata.result.Response.Results[0].length > 0 &&
					onewayflightdata.result.Response.Results[0].map((item) => {
						if (item.AirlineCode === event.target.name) {
							tbo.push({
								...item,
							});
						}
					});
			}
			if (onewayflightdataEtrav) {
				onewayflightdataEtrav &&
					onewayflightdataEtrav.result &&
					onewayflightdataEtrav.result.TripDetails[0].Flights.length > 0 &&
					onewayflightdataEtrav.result.TripDetails[0].Flights.map((item) => {
						if (item.Airline_Code === event.target.name) {
							etrav.push({
								...item,
							});
						}
					});
			}
			if (tripjack) {
				tripjack &&
					tripjack.result &&
					tripjack.result.searchResult &&
					tripjack.result.searchResult.tripInfos &&
					tripjack.result.searchResult.tripInfos.ONWARD &&
					tripjack.result.searchResult.tripInfos.ONWARD.length > 0 &&
					tripjack.result.searchResult.tripInfos.ONWARD.map((item) => {
						if (item.sI[0].fD.aI.code === event.target.name) {
							tjk.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tbo, ...etrav, ...tjk];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: a.Fares
						? Number(Math.round(a.Fares.Total_Amount))
						: a.totalPriceList
						? Number(Math.round(a.totalPriceList.fd.Total_Amount))
						: Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  );
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: b.Fares
						? Number(Math.round(b.Fares.Total_Amount))
						: b.totalPriceList
						? Number(Math.round(b.totalPriceList.fd.Total_Amount))
						: Number(Math.round(b.Fare.Total_Amount)) -
						  Number(Math.round(b.Fare.MFB_Discount));
					return first - second;
				});
			console.log('filterAilrine', filterDataStop);
			setResult(filterDataStop);
		}
	};
	const handleMorning = (event) => {
		setMorning(event.target.checked);
		SethasMoreResult(true);
		SetScrollValResult(10);
		if (morning) {
			setResult([]);
			SetRule(event.target.checked);
		} else {
			SetRule(event.target.checked);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];
			let etrav = [];
			let tjk = [];
			if (onewayflightdata) {
				onewayflightdata &&
					onewayflightdata.result &&
					onewayflightdata.result.Response &&
					onewayflightdata.result.Response.Results[0].length > 0 &&
					onewayflightdata.result.Response.Results[0].map((item) => {
						console.log(
							item.Segments[0].slice(0, 1).map((i) => {
								return i.Origin.DepTime.substr(11, 2);
							})
						);
						console.log(
							'Dept',
							item.Segments[0].slice(0, 1).map((i) => {
								return i.Destination.ArrTime.substr(11, 2);
							})
						);
						let a = item.Segments[0].slice(0, 1).map((i) => {
							return i.Origin.DepTime.substr(11, 2);
						});
						let b = item.Segments[0].slice(0, 1).map((i) => {
							return i.Destination.ArrTime.substr(11, 2);
						});
						if (a >= 4 && b <= 12) {
							tbo.push({
								...item,
							});
						}
					});
			}
			// if (onewayflightdataEtrav) {
			//   onewayflightdataEtrav &&
			//     onewayflightdataEtrav.result &&
			//     onewayflightdataEtrav.result.TripDetails[0] &&
			//     onewayflightdataEtrav.result.TripDetails[0].Flights.length > 0 &&
			//     onewayflightdataEtrav.result.TripDetails[0].Flights.map((item) => {
			//       if (item.Segments.length === 1) {
			//         etrav.push({
			//           ...item,
			//         });
			//       }
			//     });
			// }
			// if (tripjack) {
			//   tripjack &&
			//     tripjack.result &&
			//     tripjack.result.searchResult &&
			//     tripjack.result.searchResult.tripInfos&&
			//     tripjack.result.searchResult.tripInfos.ONWARD&&
			//     tripjack.result.searchResult.tripInfos.ONWARD.length > 0 &&
			//     tripjack.result.searchResult.tripInfos.ONWARD.map((item) => {
			//       if (item.sI.length === 1) {
			//         tjk.push({
			//           ...item,
			//         });
			//       }
			//     });
			// }
			filterCombineStop = [...tbo];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: a.Fares
						? Number(Math.round(a.Fares.Total_Amount))
						: a.totalPriceList
						? Number(Math.round(a.totalPriceList.fd.Total_Amount))
						: Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  );
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: b.Fares
						? Number(Math.round(b.Fares.Total_Amount))
						: b.totalPriceList
						? Number(Math.round(b.totalPriceList.fd.Total_Amount))
						: Number(Math.round(b.Fare.Total_Amount)) -
						  Number(Math.round(b.Fare.MFB_Discount));
					return first - second;
				});
			console.log('stop3', filterDataStop);
			setResult(filterDataStop);
		}
	};
	console.log('filterData', filterData);
	return (
		<>
			{/* Header Starting */}
			<HeaderSection id={1} />
			{/* Header Ending */}

			{/* Oneway Form Starting */}
			{!ltbo || !letrav ? (
				<Box
					styel={{
						visibility: true ? 'hidden' : 'visible',
						opacity: true ? 0 : 1,
						transition: true
							? ''
							: 'visibility 0s linear 0.33s, opacity 0.33s linear',
						transitionDelay: true ? '' : '0s',
						// paddingLeft:20,
						// paddingRight:20
					}}
					className={classes.searhform}>
					{/* <Container> */}
					<Modifyfdform />
					{/* <SearchFlight /> */}
					{/* </Container> */}
				</Box>
			) : (
				<Linear />
			)}
			{/* Oneway Form Ending */}
			<Box
				style={{
					paddingLeft: matches2 ? 0 : 200,
					paddingRight: matches2 ? 0 : 200,
					background: '#e5eef4',
				}}>
				<Box>
					{/* <Container> */}
					<Box style={{ padding: matches ? 10 : 10 }}>
						{!ltbo || !letrav || !loadingfd ? (
							// <Skeleton variant='rectangular' width='100%' height={118} />
							<></>
						) : (
							<marquee direction='right'>
								<img
									src={flightrun}
									style={{ width: 100, height: 32, marginTop: 10 }}
								/>
							</marquee>
						)}

						<Grid container style={{ marginTop: 20 }} spacing={2}>
							{matches2 ? <></> : ''}
							<Grid item xs={12} md={12} lg={12}>
								<Box
									justifyContent='space-between'
									alignItems='center'
									display='flex'>
									<Box alignItems='center' display='flex'>
										{matches ? (
											<h3>{`Flights from ${selectedOrigin.city_name} to ${
												selectedDepart.city_name
											}(${filterData ? filterData.length : 0})`}</h3>
										) : (
											<h3>{`Flights from ${selectedOrigin.city_name} to ${
												selectedDepart.city_name
											}(${filterData ? filterData.length : 0})`}</h3>
										)}
										{filterData?.length > 0 ? (
											ltbo || letrav || loadingfd ? (
												<img src={Loader} style={{ height: 60 }} />
											) : (
												''
											)
										) : (
											''
										)}
									</Box>
								</Box>

								{loading2 ? (
									[0, 1, 2, 3, 4].map((i) => {
										return <SkeletonOneway />;
									})
								) : ltbo && letrav && loadingfd ? (
									[0, 1, 2, 3, 4].map((i) => {
										return <SkeletonOneway />;
									})
								) : rule || (result && result.length > 0) ? (
									result && result.length > 0 ? (
										<InfiniteScroll
											dataLength={scrollValResult}
											next={fetchMoreDataResult}
											hasMore={hasMoreresult}
											loader={<h4>Loading...</h4>}
											endMessage={
												<p style={{ textAlign: 'center' }}>
													<b>No More Flights</b>
												</p>
											}>
											{result.map((item, index) => {
												return index < scrollValResult ? (
													matches ? (
														<OneWayMobileRow
															item={item}
															loading={loading}
															timeConvert={timeConvert}
															location={location}
															net={net}
															onewayflightdata={onewayflightdata}
															onewayflightdataEtrav={onewayflightdataEtrav}
															convertH2M={convertH2M}
															index={index}
														/>
													) : (
														<>
															<OneWayDesktopRow
																item={item}
																loading={loading}
																timeConvert={timeConvert}
																location={location}
																onewayflightdata={onewayflightdata}
																onewayflightdataEtrav={onewayflightdataEtrav}
																convertH2M={convertH2M}
																net={net}
															/>
														</>
													)
												) : (
													''
												);
											})}
										</InfiniteScroll>
									) : (
										<div
											style={{
												padding: '30px 15px',
												background: 'white',
												borderBottom: '1px solid #e6e6e6',
												textAlign: 'center',
											}}>
											<img src={flightnotfound}></img>
											<p style={{ fontSize: '24px', textAlign: 'center' }}>
												Sorry No Flight Found !!
											</p>
										</div>
									)
								) : filterData && filterData?.length > 0 ? (
									<InfiniteScroll
										dataLength={scrollVal}
										next={fetchMoreData}
										hasMore={hasMorefilterData}
										loader={<h4 style={{ textAlign: 'center' }}>Loading...</h4>}
										endMessage={
											<p style={{ textAlign: 'center' }}>
												<b>No More Flights</b>
											</p>
										}>
										{filterData?.map((item, index) => {
											return index < scrollVal ? (
												matches ? (
													<>
														<FDOnewayrow
															item={item}
															loading={loadingfd}
															convertH2M={convertH2M}
														/>
													</>
												) : (
													<FDOnewayrow
														item={item}
														loading={loadingfd}
														convertH2M={convertH2M}
													/>
													// <></>
												)
											) : (
												''
											);
										})}
									</InfiniteScroll>
								) : ltbo === false &&
								  letrav === false &&
								  loadingfd === false ? (
									<div
										style={{
											padding: '30px 15px',
											background: 'white',
											borderBottom: '1px solid #e6e6e6',
											textAlign: 'center',
										}}>
										<img src={flightnotfound}></img>
										<p style={{ fontSize: '24px', textAlign: 'center' }}>
											Sorry No Flight Found !!
										</p>
									</div>
								) : (
									[0, 1, 2, 3, 4].map((i) => {
										return <SkeletonOneway />;
									})
								)}
							</Grid>
						</Grid>
					</Box>
					{/* </Container> */}
				</Box>
			</Box>
			<Footer />
			<FlightDetailModal />
			<Drawers />
			<Login />
			{/* <ErrorModal /> */}
		</>
	);
};

export default FDOneway;
