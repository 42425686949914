import React from 'react';
import './sidebar.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	agentloginSuccess,
	supplierloginSuccess,
	travellerloginSuccess,
	walletagentSuccess,
	walletSuccess,
} from '../../reducers/UserReducer';
import { setSiderBar } from '../../reducers/UiReducer';

const Sidebar = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { sidebar } = useSelector((state) => state.ui);
	const { logo } = useSelector((state) => state.home);
	const { supplierlogin } = useSelector((state) => state.user);
	return (
		<>
			{/* <!-- Sidebar --> */}
			<div className='bg-white' id='sidebar-wrapper'>
				<div
					className='sidebar-heading text-center py-4 primary-text fs-4 fw-bold text-uppercase border-bottom'
					style={{ cursor: 'pointer' }}>
					<img
						onClick={() => history.push('/')}
						src={logo?.result[0].logo}
						style={{ height: 40, width: '100%' }}
					/>
				</div>
				<div className='list-group list-group-flush my-3'>
					<div
						style={{ cursor: 'pointer' }}
						onClick={() => {
							dispatch(setSiderBar('dashboard'));
							history.push({
								pathname: '/dashboard',
								state: {
									title: 'Dashboard',
								},
							});
						}}
						className={
							sidebar === 'dashboard'
								? 'list-group-item list-group-item-action bg-transparent second-text active'
								: 'list-group-item list-group-item-action bg-transparent second-text fw-bold'
						}>
						<i className='fas fa-tachometer-alt me-2'></i>Dashboard
					</div>
					<div
						style={{ cursor: 'pointer' }}
						onClick={() => {
							dispatch(setSiderBar('project'));
							history.push({
								pathname: '/editprofile',
								state: {
									title: 'Profile',
								},
							});
						}}
						className={
							sidebar === 'project'
								? 'list-group-item list-group-item-action bg-transparent second-text active'
								: 'list-group-item list-group-item-action bg-transparent second-text fw-bold'
						}>
						<i className='fas fa-user me-2'></i>Profile
					</div>
					<div
						style={{ cursor: 'pointer' }}
						onClick={() => {
							dispatch(setSiderBar('ana'));
							history.push({
								pathname: '/changepassword',
								state: {
									title: 'Change Password',
								},
							});
						}}
						className={
							sidebar === 'ana'
								? 'list-group-item list-group-item-action bg-transparent second-text active'
								: 'list-group-item list-group-item-action bg-transparent second-text fw-bold'
						}>
						<i className='fas fa-key me-2'></i>Change Password
					</div>
					{supplierlogin && supplierlogin.status === 200 ? (
						<>
							<div
								style={{ cursor: 'pointer' }}
								onClick={() => {
									dispatch(setSiderBar('addflight'));
									history.push({
										pathname: '/addflight',
										state: {
											title: 'Add Sector',
										},
									});
								}}
								className={
									sidebar === 'addflight'
										? 'list-group-item list-group-item-action bg-transparent second-text active'
										: 'list-group-item list-group-item-action bg-transparent second-text fw-bold'
								}>
								<i className='fas fa-history me-2'></i>Add Sector
							</div>
						</>
					) : (
						<div
							style={{ cursor: 'pointer' }}
							onClick={() => {
								dispatch(setSiderBar('re'));
								history.push({
									pathname: '/managemarkup',
									state: {
										title: 'Manage Markup',
									},
								});
							}}
							className={
								sidebar === 're'
									? 'list-group-item list-group-item-action bg-transparent second-text active'
									: 'list-group-item list-group-item-action bg-transparent second-text fw-bold'
							}>
							<i className='fas fa-tasks me-2'></i>Manage Markup
						</div>
					)}
					{supplierlogin && supplierlogin.status === 200 && (
						<div
							style={{ cursor: 'pointer' }}
							onClick={() => {
								dispatch(setSiderBar('sectorlist'));
								history.push({
									pathname: '/sectorlist',
									state: {
										title: 'Sector',
									},
								});
							}}
							className={
								sidebar === 'sectorlist'
									? 'list-group-item list-group-item-action bg-transparent second-text active'
									: 'list-group-item list-group-item-action bg-transparent second-text fw-bold'
							}>
							<i className='fas fa-history me-2'></i>Sector List
						</div>
					)}
					<div
						style={{ cursor: 'pointer' }}
						onClick={() => {
							dispatch(setSiderBar('pro'));
							history.push({
								pathname: '/transactionhistory',
								state: {
									title: 'Transaction History',
								},
							});
						}}
						className={
							sidebar === 'pro'
								? 'list-group-item list-group-item-action bg-transparent second-text active'
								: 'list-group-item list-group-item-action bg-transparent second-text fw-bold'
						}>
						<i className='fas fa-history me-2'></i>Transaction History
					</div>
					<div
						style={{ cursor: 'pointer' }}
						onClick={() => {
							dispatch(setSiderBar('book'));
							history.push({
								pathname: '/bookhistory',
								state: {
									title: 'Booking History',
								},
							});
						}}
						className={
							sidebar === 'book'
								? 'list-group-item list-group-item-action bg-transparent second-text active'
								: 'list-group-item list-group-item-action bg-transparent second-text fw-bold'
						}>
						<i className='fas fa-history me-2'></i>BookingHistory
					</div>
					<div
						style={{ cursor: 'pointer' }}
						onClick={() => {
							dispatch(travellerloginSuccess(null));
							dispatch(agentloginSuccess(null));
							dispatch(walletSuccess(null));
							dispatch(walletagentSuccess(null));
							sessionStorage.removeItem('userInfoAgent');
							sessionStorage.removeItem('supplierlogin');
							dispatch(supplierloginSuccess(null));
							history.push('/');
						}}
						className='list-group-item list-group-item-action bg-transparent text-danger fw-bold'>
						<i className='fas fa-power-off me-2'></i>Logout
					</div>
				</div>
			</div>
			{/* <!-- /#sidebar-wrapper --> */}
		</>
	);
};

export default Sidebar;
