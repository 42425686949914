import React from 'react';
import { makeStyles } from '@material-ui/core';
import Loader from '../../assets/images/Loader.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme) => ({
	loaderCont: {
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundColor: '#F8F8F8AD',
	},
	loader: {
		left: '50%',
		top: '50%',
		zIndex: 1000,
		position: 'absolute',
	},
}));

const LoaderModal = () => {
	const classes = useStyles();

	return (
		<div className={classes.loaderCont}>
			<div className={classes.loader}>
				<div className='spinner'></div>
				{/* <CircularProgress color='secondary' /> */}
			</div>
		</div>
	);
};

export default LoaderModal;
